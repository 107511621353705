import React from "react"
import "./transcript-disclaimer.css"

export default function Disclaimer() {
  return (
    <div className="transcriptWarning">
      This program has been transcribed using automated software tools, made
      possible through a collaboration between the American Archive of Public
      Broadcasting and Pop Up Archive. Please note that no automated
      transcription is perfect nor is it intended to replace human transcription
      labor. If you would like to contribute corrections to this transcript,
      please contact MITH at mith@umd.edu.
    </div>
  )
}
